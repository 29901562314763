import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    list: [
      {
        id: 1,

        headTitle: "真人首存68%",
        icon: require("@/assets/images/icon1.png"),
        imgSrc: require("@/assets/images/1.png"),
        videoSrc: require("@/assets/videos/1.mp4"),
        posterSrc: require("@/assets/videos/111.png"),
        cardSrc: require("@/assets/videos/item1.png"),
        inside: require("@/assets/images/inside-img1.png"),
        mobileSrc: require("@/assets/videos/mobile1.png"),
        downloadSrc: require("@/assets/videos/download1.png"),
        lookSrc: require("@/assets/videos/look1.png"),
        childSrc: [
          require("@/assets/images/h5/childSrc1/1.png"),
          require("@/assets/images/h5/childSrc1/2.png"),
          require("@/assets/images/h5/childSrc1/3.png"),
          require("@/assets/images/h5/childSrc1/4.png"),
          require("@/assets/images/h5/childSrc1/5.png"),
          require("@/assets/images/h5/childSrc1/6.png"),
          require("@/assets/images/h5/childSrc1/7.png"),
          require("@/assets/images/h5/childSrc1/8.png"),
          require("@/assets/images/h5/childSrc1/9.png"),
          require("@/assets/images/h5/childSrc1/10.png"),
          require("@/assets/images/h5/childSrc1/11.png"),
          require("@/assets/images/h5/childSrc1/12.png"),
          require("@/assets/images/h5/childSrc1/13.png"),
          require("@/assets/images/h5/childSrc1/14.png"),
          require("@/assets/images/h5/childSrc1/15.png"),
          require("@/assets/images/h5/childSrc1/16.png"),
        ],
        // detailsSrc: require("@/assets/videos/11.png"),
        title: "极速转卡存款教程",
        classification: "simple",
        summary:
          "极速转卡，让您体验飞一般的到账感觉，开云体育最具玩家喜爱的存款方式之一，为您节省大量等待时间，现在点击视频即可查看点。",
        type: "deposit",
        className: "gtm-instant-deposit",
      },

      {
        id: 2,
        headTitle: "虚拟币首存15%",
        icon: require("@/assets/images/icon6.png"),
        imgSrc: require("@/assets/images/6.png"),
        videoSrc: require("@/assets/videos/6.mp4"),
        posterSrc: require("@/assets/videos/666.png"),
        cardSrc: require("@/assets/videos/item6.png"),
        inside: require("@/assets/images/inside-img6.png"),
        mobileSrc: require("@/assets/videos/mobile6.png"),
        downloadSrc: require("@/assets/videos/download6.png"),
        lookSrc: require("@/assets/videos/look6.png"),
        childSrc: [
          require("@/assets/images/h5/childSrc6/1.png"),
          require("@/assets/images/h5/childSrc6/2.png"),
          require("@/assets/images/h5/childSrc6/3.png"),
          require("@/assets/images/h5/childSrc6/4.png"),
          require("@/assets/images/h5/childSrc6/5.png"),
          require("@/assets/images/h5/childSrc6/6.png"),
          require("@/assets/images/h5/childSrc6/7.png"),
          require("@/assets/images/h5/childSrc6/8.png"),
          require("@/assets/images/h5/childSrc6/9.png"),
        ],

        // detailsSrc: require("@/assets/videos/66.png"),
        title: "虚拟币存款教程",
        classification: "fast",
        summary:
          "还在用传统方式存款？还在为久久不能到账而烦恼？虚拟币存款方式——千万开云玩家支持的不二之选，1分钟带您了解极速到账的奥秘！",
        type: "deposit",
        className: "gtm-virtual-currency-deposit",
      },

      {
        id: 3,
        headTitle: "彩票首存",
        icon: require("@/assets/images/icon4.png"),
        imgSrc: require("@/assets/images/4.png"),
        videoSrc: require("@/assets/videos/4.mp4"),
        posterSrc: require("@/assets/videos/444.png"),
        cardSrc: require("@/assets/videos/item4.png"),
        inside: require("@/assets/images/inside-img4.png"),
        mobileSrc: require("@/assets/videos/mobile4.png"),
        downloadSrc: require("@/assets/videos/download4.png"),
        lookSrc: require("@/assets/videos/look4.png"),
        childSrc: [
          require("@/assets/images/h5/childSrc4/1.png"),
          require("@/assets/images/h5/childSrc4/2.png"),
          require("@/assets/images/h5/childSrc4/3.png"),
          require("@/assets/images/h5/childSrc4/4.png"),
          require("@/assets/images/h5/childSrc4/5.png"),
          require("@/assets/images/h5/childSrc4/6.png"),
          require("@/assets/images/h5/childSrc4/7.png"),
          require("@/assets/images/h5/childSrc4/8.png"),
          require("@/assets/images/h5/childSrc4/9.png"),
          require("@/assets/images/h5/childSrc4/10.png"),
          require("@/assets/images/h5/childSrc4/11.png"),
        ],

        // detailsSrc: require("@/assets/videos/44.png"),
        title: "网银转账存款教程",
        classification: "simple",
        summary:
          "网银转账是开云体育用户存款方式首选之一；打开您手机任意一个网银APP，1分钟即可完成转账，立刻点击了解吧！",
        type: "deposit",
        className: "gtm-online-banking-transfer",
      },
      {
        id: 4,
        headTitle: "体育首存",
        icon: require("@/assets/images/icon3.png"),
        imgSrc: require("@/assets/images/3.png"),
        videoSrc: require("@/assets/videos/3.mp4"),

        posterSrc: require("@/assets/videos/333.png"),
        cardSrc: require("@/assets/videos/item3.png"),
        inside: require("@/assets/images/inside-img3.png"),
        mobileSrc: require("@/assets/videos/mobile3.png"),
        downloadSrc: require("@/assets/videos/download3.png"),
        lookSrc: require("@/assets/videos/look3.png"),
        childSrc: [
          require("@/assets/images/h5/childSrc3/1.png"),
          require("@/assets/images/h5/childSrc3/2.png"),
          require("@/assets/images/h5/childSrc3/3.png"),
          require("@/assets/images/h5/childSrc3/4.png"),
          require("@/assets/images/h5/childSrc3/5.png"),
          require("@/assets/images/h5/childSrc3/6.png"),
          require("@/assets/images/h5/childSrc3/7.png"),
          require("@/assets/images/h5/childSrc3/8.png"),
          require("@/assets/images/h5/childSrc3/9.png"),
          require("@/assets/images/h5/childSrc3/10.png"),
          require("@/assets/images/h5/childSrc3/11.png"),
        ],

        // detailsSrc: require("@/assets/videos/33.png"),
        title: "银行卡转卡存款教程",
        classification: "simple",
        summary:
          "银行卡转卡，新玩家钟爱的存款方式；各大银行网银任选，转账便捷、操作简单，绝对是您的不二选择，立刻点击查看吧！",
        type: "deposit",
        className: "gtm-card-to-card-deposit",
      },
      {
        id: 5,
        headTitle: "电竞首存",
        icon: require("@/assets/images/icon5.png"),
        imgSrc: require("@/assets/images/5.png"),
        videoSrc: require("@/assets/videos/5.mp4"),
        posterSrc: require("@/assets/videos/555.png"),
        cardSrc: require("@/assets/videos/item5.png"),
        inside: require("@/assets/images/inside-img5.png"),
        mobileSrc: require("@/assets/videos/mobile5.png"),
        downloadSrc: require("@/assets/videos/download5.png"),
        lookSrc: require("@/assets/videos/look5.png"),
        childSrc: [
          require("@/assets/images/h5/childSrc5/1.png"),
          require("@/assets/images/h5/childSrc5/2.png"),
          require("@/assets/images/h5/childSrc5/3.png"),
          require("@/assets/images/h5/childSrc5/4.png"),
          require("@/assets/images/h5/childSrc5/5.png"),
          require("@/assets/images/h5/childSrc5/6.png"),
          require("@/assets/images/h5/childSrc5/7.png"),
          require("@/assets/images/h5/childSrc5/8.png"),
          require("@/assets/images/h5/childSrc5/9.png"),
        ],

        // detailsSrc: require("@/assets/videos/55.png"),
        title: "易币付存款教程",
        classification: "fast",
        summary:
          "想体验3秒到账的极速存款体验吗？易币付虚拟钱包，千万用户选择知品牌，安全！私密！汇率高！现在就点击了解吧！",
        type: "deposit",
        className: "gtm-yibifu-deposit",
      },
      {
        id: 6,
        headTitle: "老虎机首存",
        icon: require("@/assets/images/icon2.png"),
        imgSrc: require("@/assets/images/2.png"),
        videoSrc: require("@/assets/videos/2.mp4"),
        posterSrc: require("@/assets/videos/222.png"),
        cardSrc: require("@/assets/videos/item2.png"),
        inside: require("@/assets/images/inside-img2.png"),
        mobileSrc: require("@/assets/videos/mobile2.png"),
        downloadSrc: require("@/assets/videos/download2.png"),
        lookSrc: require("@/assets/videos/look2.png"),
        childSrc: [
          require("@/assets/images/h5/childSrc2/1.png"),
          require("@/assets/images/h5/childSrc2/2.png"),
          require("@/assets/images/h5/childSrc2/3.png"),
          require("@/assets/images/h5/childSrc2/4.png"),
          require("@/assets/images/h5/childSrc2/5.png"),
          require("@/assets/images/h5/childSrc2/6.png"),
          require("@/assets/images/h5/childSrc2/7.png"),
          require("@/assets/images/h5/childSrc2/8.png"),
          require("@/assets/images/h5/childSrc2/9.png"),
          require("@/assets/images/h5/childSrc2/10.png"),
        ],

        // detailsSrc: require("@/assets/videos/22.png"),
        title: "EBpay存款教程",
        classification: "fast",
        summary:
          "还在为支付宝微信无法存款而烦恼吗？Ebpay存款，1 : 1人民币等比虚拟币，为您解决常规方式无法存款的烦恼，赶紧点击了解吧！",
        type: "deposit",
        className: "gtm-epay-deposit",
      },

      // {
      //   id: 7,
      //   headTitle: "KOIpay存款教程",
      //   icon: require("@/assets/images/icon7.png"),
      //   imgSrc: require("@/assets/images/7.png"),
      //   videoSrc: require("@/assets/videos/7.mp4"),
      //   posterSrc: require("@/assets/videos/777.png"),
      //   // cardSrc: require("@/assets/videos/item8.png"),
      //   // inside: require("@/assets/images/inside-img8.png"),
      //   mobileSrc: require("@/assets/videos/mobile7.png"),
      //   downloadSrc: require("@/assets/videos/download7.png"),
      //   lookSrc: require("@/assets/videos/look7.png"),
      //   childSrc: [
      //     require("@/assets/images/h5/childSrc7/1.png"),
      //     require("@/assets/images/h5/childSrc7/2.png"),
      //     require("@/assets/images/h5/childSrc7/3.png"),
      //     require("@/assets/images/h5/childSrc7/4.png"),
      //     require("@/assets/images/h5/childSrc7/5.png"),
      //     require("@/assets/images/h5/childSrc7/6.png"),
      //     require("@/assets/images/h5/childSrc7/7.png"),
      //     require("@/assets/images/h5/childSrc7/8.png"),
      //     require("@/assets/images/h5/childSrc7/9.png"),
      //     require("@/assets/images/h5/childSrc7/10.png"),
      //   ],

      //   // detailsSrc: require("@/assets/videos/77.png"),
      //   title: "KOIpay存款教程",
      //   classification: "fast",
      //   summary:
      //     "还在为没有存款方式发愁吗？ KOIpay钱包———恒定1:1人民币汇率,全球数十万人使用，秒级交易匹配,世界范围内已有上千家大型商户支持KB币，全球支付无障碍。",
      //   type: "deposit",
      //   className: "gtm-koipay-deposit",

      //   // showCard: true,
      // },
      {
        id: 8,
        headTitle: "预约取款",
        icon: require("@/assets/images/icon8.png"),
        imgSrc: require("@/assets/images/8.png"),
        videoSrc: require("@/assets/videos/8.mp4"),
        posterSrc: require("@/assets/videos/888.png"),
        cardSrc: require("@/assets/videos/item8.png"),
        inside: require("@/assets/images/inside-img8.png"),
        mobileSrc: require("@/assets/videos/mobile8.png"),
        downloadSrc: require("@/assets/videos/download8.png"),
        lookSrc: require("@/assets/videos/look8.png"),
        childSrc: [
          require("@/assets/images/h5/childSrc8/1.png"),
          require("@/assets/images/h5/childSrc8/2.png"),
          require("@/assets/images/h5/childSrc8/3.png"),
          require("@/assets/images/h5/childSrc8/4.png"),
          require("@/assets/images/h5/childSrc8/5.png"),
          require("@/assets/images/h5/childSrc8/6.png"),
          require("@/assets/images/h5/childSrc8/7.png"),
          require("@/assets/images/h5/childSrc8/8.png"),
          require("@/assets/images/h5/childSrc8/9.png"),
          require("@/assets/images/h5/childSrc8/10.png"),
          require("@/assets/images/h5/childSrc8/11.png"),
          require("@/assets/images/h5/childSrc8/12.png"),
          require("@/assets/images/h5/childSrc8/13.png"),
        ],

        // detailsSrc: require("@/assets/videos/77.png"),
        title: "预约取款教程",
        classification: "draw",

        summary:
          "取款还能赚钱？您没看错，这是您从未体验过的全新提款方式——取款时间任选！随心取消！提款送彩金！还等什么？现在就点击查看吧！",
        type: "withdraw",
        showCard: true,
        className: "gtm-appoint-to-withdraw",
      },
    ],
  },
  getters: {},

  actions: {},
  mutations: {},
  modules: {},
});
