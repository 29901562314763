<template>
  <div class="home">
    <!-- 头部 -->
    <HomeHead></HomeHead>
    <!-- banner图 -->
    <div class="banner">
      <img class="title-img" src="../assets/images/title.png" alt="" />
      <img class="banner-img" src="../assets/images/bannar.png" alt="" />
    </div>

    <div class="white-container">
      <div class="tab-container">
        <div
          class="card-tab pointer"
          :class="{ tabType: tabType === item.type, [item.type]: true }"
          v-for="item in tabs"
          :key="item.type"
          @click="activeClick(item)"
        >
          {{ item.text }}
        </div>
      </div>
      <div>
        <transition-group
          appear
          name="list"
          :css="false"
          @enter="enter"
          @leave="leave"
        >
          <div
            v-if="tabType == 'all' || tabType == 'deposit'"
            :key="tabType + '1'"
          >
            <div class="card-title"><span></span> 充值速度最快 — 重点推荐</div>
            <div class="line"></div>
            <div class="card-container">
              <Card
                v-for="item in handleList('fast')"
                :key="item.nanoid"
                :card="item"
              ></Card>
            </div>
          </div>

          <div
            v-if="tabType == 'all' || tabType == 'deposit'"
            :key="tabType + '2'"
          >
            <div class="card-title"><span></span>银行卡充值 — 简单易用</div>
            <div class="line"></div>
            <div class="card-container">
              <Card
                v-for="item in handleList('simple')"
                :key="item.nanoid"
                :card="item"
              ></Card>
            </div>
          </div>

          <div
            v-if="tabType == 'all' || tabType == 'withdraw'"
            :key="tabType + '3'"
          >
            <div class="card-title"><span></span>提款首选 — 额外赠送</div>
            <div class="line"></div>
            <div class="card-container">
              <Card
                v-for="item in handleList('draw')"
                :key="item.nanoid"
                :card="item"
              ></Card>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <HomeFooter
      :style="{ marginTop: this.tabType === 'withdraw' ? '117px' : 0 }"
    ></HomeFooter>
  </div>
</template>

<script>
import gsap from "gsap";

import HomeHead from "./Head.vue";
import HomeFooter from "./HomeFooter.vue";
import Card from "./Card.vue";
import { mapState, mapMutations } from "vuex";

import { nanoid } from "nanoid";
// import _ from "lodash";
export default {
  name: "Home",
  data() {
    return {
      tabType: "all",
      tabs: [
        { type: "all", text: "全部" },
        { type: "deposit", text: "存款" },
        { type: "withdraw", text: "取款" },
      ],
    };
  },

  computed: {
    ...mapState({
      cardList: (state) => state.list,
    }),
    handleList() {
      return (type) => {
        return this.cardList.filter((item) => item.classification === type);
      };
    },
    // List() {
    //   let cardList = [];
    //   if (this.tabType === "all") {
    //     cardList = this.cardList.filter((el) => {
    //       return el.classification === 'fast'
    //     });
    //     // cardList = _.shuffle(cardList);
    //   }
    //     if (this.tabType === "deposit") {
    //     cardList = this.cardList.filter((el) => {
    //       return el.classification === 'simple'
    //     });

    //   }
    //  if(this.tabType === "withdraw") {
    //     cardList = this.cardList.filter((el) => {
    //       return el.classification === 'draw'
    //     });
    //  }

    //   return cardList;
    // },
    // List() {
    //   let cardList = [];
    //   if (this.tabType === "all") {
    //     cardList = this.cardList.map((el) => {
    //       return {
    //         ...el,
    //         nanoid: nanoid(),
    //       };
    //     });
    //     // cardList = _.shuffle(cardList);
    //   } else {
    //     cardList = this.cardList
    //       .filter((el) => el.type === this.tabType)
    //       .map((el) => {
    //         return {
    //           ...el,
    //           nanoid: nanoid(),
    //         };
    //       });
    //     // cardList = _.shuffle(cardList);
    //   }
    //   return cardList;
    // },
  },
  mounted() {},
  methods: {
    activeClick(item) {
      // if (item.type===this.tabType)    return

      this.tabType = item.type;

      // this.$set(this.cardList【1,'sex','男')
    },
    onEnter(el, done) {
      gsap.to(el, {
        opacity: 1,

        height: 0,
        // delay: el.dataset.index * 0.15,
        onComplete: done,
      });
    },
    enter(el, done) {
      const seconds = 0.3;

      gsap.from(el, {
        delay: seconds,
        opacity: 0,
        y: 20,
        // scale: 0,
        duration: seconds,
        onComplete: done,
      });
    },
    leave(el, done) {
      const seconds = 0.3;

      gsap.to(el, {
        opacity: 0,
        y: 20,
        // scale: 0,
        duration: seconds,
        onComplete: done,
      });
    },
  },
  components: { HomeHead, HomeFooter, Card },
};
</script>

<style lang="less">
.home {
  background: url("../assets/images/backround.png") no-repeat;
  background-size: 100%;

  padding: 0 13px;
  // position: relative;

  font-family: "PingFang SC";

  // background: red;

  // padding-bottom: 100px;
  // margin-bottom: 100px;
  .banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0 11px 0;

    .title-img {
      height: 29px;
      margin-bottom: 7px;
    }

    .banner-img {
      height: 195px;
      width: 326px;
    }
  }

  .white-container {
    min-height: 234px;
    // max-height: 694px;
    background: #fffefe;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    font-size: 15px;
    border-radius: 5px;
    padding: 6px;
    color: #3f3f3f;
    display: flex;
    flex-direction: column;

    img {
      height: 85px;
    }

    .tab-container {
      display: flex;
      font-size: 13px;
      line-height: 240%;
      color: #333333;
    }

    .card-tab {
      width: 43px;
      text-align: center;
    }

    .tabType {
      color: #228eff;
      font-weight: 500;
      font-size: 16px;
    }

    .card-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      // flex-wrap: nowrap;
      // background: olive;

      // >span {
      //   display: block;
      //   display: flex;
      //   flex-direction: row;
      //   flex-wrap: wrap;
      //   justify-content: space-between;
      // }
    }

    // .list-enter-from,
    // .list-leave-to {
    //   opacity: 0;
    //   transform: translateY(30px);
    // }

    // .list-enter-active,
    // .list-leave-active {
    //   transition: all 1s;
    // }

    // .list-leave-active {
    //   position: absolute;
    // }

    // .list-move {
    //   transition: all 1s;
    // }

    .list-move,
    .list-enter-active,
    .list-leave-active {
      transition: all 1s ease;
    }

    .list-enter-from,
    .list-leave-to {
      opacity: 0;
      transform: translateX(30px);
    }

    .list-leave-active {
      position: absolute;
    }
  }
}

.card-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-top: 10px;

  // div {
  //

  span {
    display: inline-block;
    height: 14px;
    background: #228eff;
    width: 3px;
    margin-right: 2px;
  }

  // }
}

.line {
  width: 325px;
  height: 1px;
  // border: 0.1px solid #D8D8D8;
  background: #d8d8d8;
  margin: 0 auto;
  margin-bottom: 8px;
  margin-top: 2px;
}
</style>
