import Vue from "vue";
import "vant/lib/index.css";
import "@vant/touch-emulator";

import { ImagePreview, Popup, Swipe, SwipeItem, Overlay, Icon } from "vant";

// use
[ImagePreview, Popup, Swipe, SwipeItem, Overlay, Icon].forEach((item) =>
  Vue.use(item)
);
