<template>
  <footer>
    <div class="footer-text">版权所有 © 2010-2024 开云 保留所有权</div>
    <!-- <div>
      <img class="footer-loge" src="@/assets/images/footer-logo.png" alt="" />
    </div> -->
  </footer>
</template>
<script>
export default {
  name: "HomeFooter",
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
footer {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  // background: olive;
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  .footer-text {
    font-family: "PingFang HK";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;

    color: #8f8f8f;
  }
  // .footer-loge {
  //   height: 23px;
  // }
}
</style>
