import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/index.vue";

Vue.use(VueRouter);

const department = ["/tuiguang", "/seo", "/kefu", "/yhwh", "/daili"];

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/details/:id",
    name: "Detail",
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/details"),
  },
  {
    path: "/cardCetails",
    name: "CardDetails",
    props($route) {
      return {
        id: $route.query.id,
        // headTitle:$toute.query.headTitle
      };
    },
    component: () =>
      import(
        /* webpackChunkName: "detail" */ "../views/details/CardDetails.vue"
      ),
  },
];

for (let i = 0; i < department.length; i++) {
  let routers = {
    path: department[i],
    component: Home,
  };
  routes.push(routers);
  console.log(routers);
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  setTimeout(() => {
    document.documentElement.scrollTop = 0;

    document.body.scrollTop = 0;
    window.pageYOffset = 0;
  }, 100);

  // document.documentElement.scrollTop = 0;
});

export default router;
