<template>
  <div class="head">
    <div class="head-box child-max500">
      <!-- <img src="@/assets/images/head-logo.png" alt="" /> -->
      <div class="left">
        <img
          class="home-head-left"
          src="@/assets/images/home-head-left.png"
          alt=""
        />
      </div>
      <div class="right">
        <vue-seamless-scroll
          :data="listData"
          class="warp"
          :class-option="classOption"
        >
          <ul class="list">
            <li class="list-item" v-for="item in listData" :key="item.id">
              <img class="list-item-img" :src="item.src" alt="" />
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Head",
  props: {},
  components: {},
  data() {
    return {
      listData: [
        {
          id: 1,
          src: require("@/assets/images/sponsorship1.png"),
        },
        {
          id: 2,
          src: require("@/assets/images/sponsorship2.png"),
        },
        {
          id: 3,
          src: require("@/assets/images/sponsorship3.png"),
        },
        {
          id: 4,
          src: require("@/assets/images/sponsorship4.png"),
        },
      
      
        
      ],
      classOption: {
        direction: 2,
        hoverStop: false,
        openTouch: false,
        limitMoveNum	:5,
        step: 0.5,
      },
    };
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
};
</script>
<style lang="less" scoped>

.head {
  @height: 56px;
  height: @height;
  margin-left: -13px;
  margin-right: -13px;

  .head-box {
    height: @height;
    background: #228eff;
    position: fixed;
    z-index: 1000;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

    .left {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 130px;

      .home-head-left {
        height: 30px;
     
      }
    }

    .right {
      width: 245px;
      height: 100%;
      overflow: hidden;  

      .list {
      
        
        height: @height;
        display: flex;
        align-items: center;
        justify-content: space-around;
      
     
        .list-item {
          flex: none;
          width: 61px;
          height: 49px;
          display: flex;
          justify-content: center;
          align-items: center;
        
          .list-item-img {
            width: 49px;
        
            
          }
        }
      }
    }
  }

  // img {
  //   height: 40px;
  // }
}

</style>
