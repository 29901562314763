<template>
  <div class="card pointer" @click="pathDetails(card.id)">
    <div :class="card.className" class="gtm"></div>
    <img :src="card.imgSrc" alt="" />
    <div class="title-container">
      <img class="icon" :src="card.icon" alt="" />
      <div class="title">{{ card.title }}</div>
    </div>
    <div class="card-content">{{ card.summary }}</div>
  </div>
</template>
<script>
export default {
  name: "Card",
  props: {
    card: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    pathDetails(id) {
      this.$router.push(`/details/${id}`);

      // this.$bus.$emit("UPDATE_ITEM");
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.card {
  width: 48%;
  margin-bottom: 10px;
  position: relative;

  .gtm {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    // background-color: rgba(255, 0, 0, 0.5);
  }
  .title-container {
    display: flex;
    align-items: center;
    .icon {
      height: 18px;
      margin-right: 4px;
    }
    .title {
      font-weight: bold;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height */

      color: #333;
    }
    margin-bottom: 3px;
  }
  .card-content {
    font-style: normal;
    font-size: 11px;

    /* or 180% */

    line-height: 16px;
    /* or 180% */
    overflow: hidden; //多出的隐藏
    text-overflow: ellipsis; //多出部分用...代替
    display: -webkit-box; //定义为盒子模型显示
    -webkit-line-clamp: 2; //用来限制在一个块元素显示的文本的行数
    -webkit-box-orient: vertical;

    color: #666666;
  }
}
</style>
